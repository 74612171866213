import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { IconType } from 'react-icons';
import { ButtonProps } from 'theme-ui';

const Button = styled.button`
  background-color: transparent;
  border: none;
`;

interface IconButtonProps extends ButtonProps {
  icon: React.ReactElement<IconType>;
  onClick?(): void;
}

const IconButton: FC<IconButtonProps> = ({ icon, onClick, ...props }) => (
  <Button type="button" onClick={onClick} {...props}>{icon}</Button>
);

export default memo(IconButton);
