import * as React from 'react'
import SignIn from '../components/SignIn'
import Page from '../components/Layout/Page';


// markup
const IndexPage = () => {
  return (
    <Page>
      <SignIn/>
    </Page>
  )
}

export default IndexPage
