import React from 'react'
import { Composition } from 'atomic-layout'
import { StaticImage } from "gatsby-plugin-image"


const areasDesktop = `
  login banner
`

const Page = ({ children }) => (
  <Composition
    width={'100%'}
    height={'100vh'}
    inline
    areas={areasDesktop}
    templateCols={'auto 600px'}
    autoRows={'minmax(0, 100%)'}
    templateRows={'minmax(0, 100%)'}
    alignItems='center'
    justifyItems='stretch'
  >
    {({ Login: LoginWrapper, Banner: BannerWrapper }) => (
      <>
        <LoginWrapper>
          {children}
        </LoginWrapper>
        <BannerWrapper>
          <StaticImage
            alt='A background for login page'
            src='../../images/login-image.png'
            objectFit='cover'
            style={{
              height: '100vh',
            }}
          />
        </BannerWrapper>
      </>
    )}
  </Composition>
)

export default Page
