import React, { useState } from 'react'
import { Composition } from 'atomic-layout'
// import { Text, Button, Image } from '../../atoms'
import { Flex,Input as MyInput, Label, Text, Button, Image, Box, Alert } from 'theme-ui';
import { useForm } from 'react-hook-form';
import MyButton from './Button';
import styled from 'styled-components'
import HypefastLogo from "../assets/hypefast.svg";
import { useMutation } from 'react-query';
import api from '../utils/api';
import auth from '../utils/auth';
import { Link, navigate } from 'gatsby';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Toast from './Editor/Toast';
import { AiFillCloseCircle } from 'react-icons/ai'
import IconButton from './Editor/IconButton';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

interface UserData {
  email?: string;
  password?: string;
}

const schema: Yup.SchemaOf<UserData> = Yup.object({
  email: Yup.string().required('Email harus diisi!').email('Format email tidak valid'),
  password: Yup.string().required('Password harus diisi!'),
});

const areasMobile = `
  header
  email
  password
  button
  footer
`

const Container = (props) => (
  <Box
    sx={{
      backgroundColor: '#f8f8f8',
    }}
    {...props}
  >
  </Box>
)

const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const {
    isLoading,
    mutate,
    status
  } = useMutation(
    'sign-in',
    (values: any) => api.login(values),
    {
      onSuccess: (resp) => {
        const { error, data } = resp;
        if (data) {
          auth.setToken(data.authorization_token);
          navigate('/page/dashboard');
          Toast({
            title: 'Login Berhasil',
            message: 'Anda telah berhasil masuk',
            type: 'success',
          });
        }

        if (error) {
          setErrorMessage(resp.message);
        }

      },
      onError: (error) => {
        console.error({ error });
      }
    }
  );

  const handleLogin = handleSubmit(values => {
    mutate({
      email: values.email,
      password: values.password
    })
  }) 

  return (
    <Flex
      sx={{
        backgroundColor: '#f8f8f8',
        height: '100vh',
        alignItems: 'center',
        px: 5,
      }}
    >
      <Composition
        width={'100%'}
        inline
        // as={Container}
        areas={areasMobile}
        templateCols={'1fr'}
        gap={20}
        padding={32}
      >
        {({ Header, Email, Password, Button, Footer }) => (
          <>
            <Header>
              <Box
                py={4}
              >
                <HypefastLogo
                  width={'100%'}
                />
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='headline'
                >
                  Welcome Back!
                </Text>
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='body'
                >
                  Login to your account to access Hypefast Landing Page Tools
                </Text>
              </Box>
            </Header>
            <Email>
              <Box
                py={1}
              >
                <Label>
                  <Text
                    variant='body'
                    // sx={{
                    //   color: '#332f11',
                    // }}
                  >
                    Email
                  </Text>
                </Label>
              </Box>
              <MyInput
                // sx={{
                //   boxShadow: 'inset 0 2px 2px, 0 2px 0',
                //   backgroundColor: '#d7cd74',
                // }}
                placeholder="Email"
                variant={errors.email && 'error' || 'input'}
                {...register("email")}
              />
              {errors.email && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.email.message}</Text>
              )}
            </Email>
            <Password style={{ position: 'relative' }}>
              <Box
                py={1}
              >
                <Label>
                  <Text variant='body'>
                    Password
                  </Text>
                </Label>
              </Box>
              <Flex>
                <MyInput
                  sx={{
                    position: 'relative'
                  }}
                  placeholder="Password"
                  variant={errors.password && 'error' || 'password'}
                  type={showPassword ? 'text' : 'password'}
                  {...register("password")}
                />
                <IconButton
                  icon={showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '3px',
                    top: '42px'
                  }}
                />
              </Flex>
              {errors.password && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.password.message}</Text>
              )}
            </Password>
            {!!errorMessage && (
              <Alert variant='error'>
                <AiFillCloseCircle fill="#df1627" />
                <Box ml={2}>
                  {errorMessage}
                </Box>
              </Alert>
            )}
            <Flex
              sx={{
                justifyContent: 'flex-end',
              }}
            >
              <Link
                to='/forgot-password'
                style={{
                  textDecoration: 'none',
                }}
              >
                <Text
                  variant='body'
                >
                  Forgot password?
                </Text>
              </Link>
            </Flex>
            <MyButton
              full
              isLoading={isLoading}
              variant='primary'
              sx={{
                // backgroundColor: '#332f11',
              }}
              onClick={handleLogin}
            >
              <Text
                variant='subtitle'
                // sx={{
                //   color: '#ffe07c'
                // }}
              >
                Login
              </Text>
            </MyButton>
            <Footer>
              {/* <Button variant='primary'>Buy</Button> */}
            </Footer>
          </>
        )}
      </Composition>

    </Flex>
  )
}

export default SignIn
